html {
  font-size: 10px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  padding: 0px;
  margin: 0px;
  font-family: 'Helvetica Neue', San-serif;
  -webkit-font-smoothing: antialiased;
  color: #fff;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}
a {
  color: #fff;
}
a:hover {
  text-decoration: underline;
}
input,
textarea {
  width: 100%;
  padding: 0.8rem 0.5rem 0.7rem 0.5rem;
  border: 0px solid #ccc;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: #f4f4f4;
  color: #666;
  outline: none;
  resize: none;
}
