body {
  background-color: #fff;
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding: 15px 15px 11px 15px;
  background-color: rgba(0,0,0,0);
}
header .logo {
  width: 150px;
  float: left;
  display: none;
}
header .logo img {
  width: 100%;
}
header #links {
  float: right;
  padding: 4px 0px 8px 0px;
}
header #links li {
  font-size: 14px;
  float: left;
  margin-right: 20px;
}
header #links li:last-child {
  margin-right: 0px;
}
section {
  width: 100%;
  position: relative;
  height: 100%;
}
section#video {
  height: 500px;
  overflow: hidden;
  position: relative;
}
section#info,
.background-video {
  width: 100%;
  background-color: #000;
  -webkit-transform: translateY(-25%);
  -moz-transform: translateY(-25%);
  -o-transform: translateY(-25%);
  -ms-transform: translateY(-25%);
  transform: translateY(-25%);
}
section#info video,
.background-video video {
  width: 100%;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
}
.video-overlay {
  width: 100%;
  height: 100%;
  padding-top: 190px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 90px;
  font-family: 'Arvo';
  text-align: left;
}
#intro {
  background-color: #ed3146;
  display: table;
  -webkit-box-shadow: inset 0px -10px 40px rgba(0,0,0,0.2);
  box-shadow: inset 0px -10px 40px rgba(0,0,0,0.2);
}
#intro .bg-image {
  background-image: url("../images/dessert.jpg");
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  opacity: 0.1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
  filter: alpha(opacity=10);
  top: 0;
  position: absolute;
  left: 0;
}
#intro .text {
  display: table-cell;
  vertical-align: middle;
  font-size: 100px;
  padding: 150px;
  font-weight: 100;
  text-align: left;
  font-family: 'Arvo';
  color: #fff;
}
#intro .text .line.sub {
  color: #000;
  font-size: 50px;
  margin-top: 20px;
}
section#short {
  background-color: #fff;
  padding: 62px 120px;
  font-size: 30px;
  border-bottom: 1px solid #eee;
  font-weight: 200;
  letter-spacing: 1px;
}
section#short p {
  color: #666;
}
section#stars {
  height: 800px;
}
section#stars .stars {
  height: 100%;
  width: 40%;
  float: left;
  overflow: hidden;
}
section#stars .content {
  height: 100%;
  width: 60%;
  float: left;
}
.big-logo {
  width: 300px;
}
@media (max-width: 1900px) {
  #intro .text {
    font-size: 90px;
  }
}
@media (max-width: 1710px) {
  #intro .text {
    font-size: 80px;
  }
}
@media (max-width: 1560px) {
  #intro .text {
    font-size: 70px;
  }
}
@media (max-width: 1400px) {
  #intro .text {
    font-size: 60px;
  }
}
@media (max-width: 1240px) {
  #intro .text {
    font-size: 50px;
  }
  #intro .text .line.sub {
    font-size: 40px;
  }
}
@media (max-width: 1100px) {
  #intro .text {
    padding: 100px;
    font-size: 50px;
  }
  #intro .text .line.sub {
    font-size: 40px;
  }
}
@media (max-width: 980px) {
  #intro .text {
    padding: 70px;
    font-size: 45px;
  }
  #intro .text .line.sub {
    font-size: 35px;
  }
}
@media (max-width: 850px) {
  #intro .text {
    padding: 70px;
    font-size: 35px;
  }
  #intro .text .line.sub {
    font-size: 25px;
  }
}
@media (max-width: 500px) {
  header #links {
    text-align: center;
    float: none;
  }
  header #links li {
    float: none;
    display: inline;
  }
  #intro .text {
    padding: 20px;
    font-size: 35px;
  }
  #intro .text .line.sub {
    font-size: 25px;
  }
  .big-logo {
    width: 200px;
  }
}
@media (max-width: 400px) {
  header #links {
    text-align: center;
    float: none;
  }
  header #links li {
    float: none;
    display: inline;
  }
  #intro .text {
    text-align: center;
    padding: 20px;
    font-size: 28px;
  }
  #intro .text .line.sub {
    font-size: 16px;
  }
  .big-logo {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}
